
function check_donation_form() {
    const form = document.querySelector('#user_donation_form');

    if (!form) {
        return;
    }

    form.addEventListener('submit', (event) => {
        if (!parseInt(form['summ'].value, 10)) {
            event.preventDefault();
            form.closest('.support__another').classList.add('field-error');
        }
    });

}

export { check_donation_form };
