import queryString from 'query-string';

const FORMAT = {
    arrayFormat: 'comma',
};
let key;
let token;
let mountpoint;

async function set_lot_cat_filter() {
    const params = queryString.parse(location.search, FORMAT);
    const values = Array.from(document.querySelectorAll('.fab-lots-category-filter input:checked')).map((x) => x.value);

    params[key] = values.length > 0 ? values : undefined;

    let hash = queryString.stringify(params, FORMAT);
    if(hash !== '') {
        hash = `?${hash}`;
    }

    const url = `${window.location.pathname}${hash}`;

    if (key == 'lc' && mountpoint) {
        mountpoint.classList.add('section-load');
        const response = await fetch(url, {
            method: 'GET',
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'X-CSRF-Token': token,
              'X-Partial-Section': 'LotSection',
            }
        });
        mountpoint.innerHTML = await response.text();
        history.replaceState({}, '', url);
        mountpoint.classList.remove('section-load');
    } else {
        window.location = url;
    }
}


function lot_category_filter() {
    const box = document.querySelector('.fab-lots-category-filter');
    if (!box) {
        return;
    }
    key = box.dataset.key;
    token = document.querySelector('meta[name="csrf-token"]').content;
    mountpoint = document.getElementById('section_lot_list');
    document.querySelectorAll('.fab-lots-category-filter input').forEach((node) => {
        node.addEventListener('click', set_lot_cat_filter);
    });
}

export { lot_category_filter };
