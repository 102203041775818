function check_profile_form() {

    const form = document.querySelector('#settings-form');
    if (!form) {
        return;
    }

    const name = form['settings-name'];
    const last_name = form['settings-lastname'];
    const phone = form['settings-phone'];
    const nickname = form['nickname'];

    form.querySelectorAll('.settings-input input').forEach((x) => {
        x.addEventListener('focus', function() {
            this.classList.remove('error')
        });
    })

    function validate() {
        let valid = true;
        const regex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
        if (!regex.test(phone.value)) {
            valid = false;
            phone.classList.add('error');
        }
        if (name.value.length < 3) {
            valid = false;
            name.classList.add('error');
        }
        if (last_name.value.length < 3) {
            valid = false;
            last_name.classList.add('error');
        }
        if (form['anonymous'].checked && nickname.value.length < 1) {
            valid = false;
            nickname.classList.add('error');
        }
        return valid;
    }


    form.addEventListener('ajax:before', (event) => {
        if (!validate()) {
            event.preventDefault();
        }
    });

    form.addEventListener('ajax:success', (event) => {
        const [data, status, xhr] = event.detail;
        if (data.status == 'ok') {
            try {
                document.querySelector('#edit-form-message').style.opacity = '1';
                const flash = document.querySelector('.settings-form-flash-alert');
                if (flash) {
                    flash.remove();
                }
            } catch (e) {
                // empty
            }
        } else {
            alert('Please fill all required fields');
        }
    });
}


export { check_profile_form };