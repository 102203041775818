import consumer from "./consumer"
import { add_message } from "../src/messages/add_message.js";


(() => {
    const auth_user = document.querySelector('.messages-list');
    if (!auth_user) {
        return;
    }

    const WebNotificationsChannel = consumer.subscriptions.create({ channel: "WebNotificationsChannel"}, {
        // connected() {
        //     console.log('Connected to WebNotificationsChannel');
        // },
        received(data) {
            add_message(data);
            // if (data.action === 'update_lot_data') {
            //     update_lot_data(data);
            // }
        },
    });
})();

