import Cleave from 'cleave.js';

function give_form() {
    const form = document.querySelector('#give-bet-form');
    const button = document.querySelector('#give-submit');
    const input = document.querySelector('#give-bet-input');

    if (!form || !button || !input) {
        return;
    }

    const minimum = parseInt(input.value.replace(/ +/g, ''), 10);

    const cleave = new Cleave(input, {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: ' '
    });

    const check_value = (node) => {
        return parseInt(node.value.replace(/ +/g, ''), 10) < minimum;
    }

    input.addEventListener('input', function(){
        button.disabled = check_value(this);
    });

    form.addEventListener('ajax:before', (event) => {
        const value = parseInt(event.target['bet'].value.replace(/ +/g, ''), 10) || 0;
        if (value < minimum) {
            event.preventDefault();
            $('.overlay_error').fadeIn();
        }
    });


}

export { give_form };
