
function add_message(data) {
    const box = document.querySelector('.notification');
    if (!box) {
        return;
    }

    const list = box.querySelector('.messages-list');
    const today = document.querySelector('.notification__day-today');
    const message = `<div class="notification__message notification__message_online"><div class="notification__info"><p class="notification__text">${data.html}</p></div><span class="notification__date">${data.time}</span></div>`;

    let template = '';
    box.classList.add('has_new');

    if (today) {
        today.querySelector('.notification__day_main').insertAdjacentHTML('afterend', message);
        return;
    } else {
        template = `<div class="notification__day notification__day-today">
        <p class="notification__day_main">Сьогодні</p>
        <div class="notification__message notification__message_online">
            <div class="notification__info">
                <p class="notification__text">${data.html}</p>
            </div>
            <span class="notification__date">${data.time}</span>
        </div></div>`;
    }

    if (list.dataset.present === 'True') {
        list.insertAdjacentHTML('afterbegin', template);
    } else {
        list.dataset.present = 'True';
        list.innerHTML = template;
    }
}

export { add_message };

