import consumer from "./consumer"
import { update_lot_data } from '../src/ws/update_lot_data';

(() => {
    const lot = document.getElementById('lot-channel-item');
    const auth_user = document.querySelector('.messages-list');

    if (!lot || !auth_user) {
        return;
    }

    const id = parseInt(lot.dataset.id, 10);
    const LotChannel = consumer.subscriptions.create({ channel: "LotChannel", room: id}, {
        // connected() {},
        received(data) {
            if (data.action === 'update_lot_data') {
                update_lot_data(data);
            }
            // this.appendLine(data)
        },

        // appendLine(data) {
        //   const html = this.createLine(data)
        //   const element = document.querySelector("[data-chat-room='Best Room']")
        //   element.insertAdjacentHTML("beforeend", html)
        // },

        // createLine(data) {
        //   return `
        //     <article class="chat-line">
        //       <span class="speaker">${data["sent_by"]}</span>
        //       <span class="body">${data["body"]}</span>
        //     </article>
        //   `
        // }
    });

    // setTimeout(() => {
    //     console.log("Send!!!!");
    //     LotChannel.send({name: 'monty'});
    // }, 3000);



    // const id = parseInt(lot.dataset.id, 10);
    // const step_button = document.querySelector('[data-event="send-bet"]');


    // const LotChannel = consumer.subscriptions.create({ channel: "LotChannel", room: id }, {
    //     received(data) {
    //         console.log(data);
    //         // this.appendLine(data)
    //     },

    //     // appendLine(data) {
    //     //   const html = this.createLine(data)
    //     //   const element = document.querySelector("[data-chat-room='Best Room']")
    //     //   element.insertAdjacentHTML("beforeend", html)
    //     // },

    //     // createLine(data) {
    //     //   return `
    //     //     <article class="chat-line">
    //     //       <span class="speaker">${data["sent_by"]}</span>
    //     //       <span class="body">${data["body"]}</span>
    //     //     </article>
    //     //   `
    //     // }
    // });


    // step_button.addEventListener('click', () => {
    //     LotChannel.send({id: id});
    // });

    // // setTimeout(() => {
    // //     console.log("Send!!!!");
    // //     LotChannel.send({name: 'monty'});
    // // }, 3000);


    // // console.log(consumer.subscriptions);
})();

