import Cleave from 'cleave.js';

function bet_form() {
    const form = document.querySelector('#user-bet-form');
    const buttons = document.querySelectorAll('.rate-lot__items button');
    window.LOT = window.LOT || {};

    if (!form) {
        return;
    }

    window.LOT['total'] = parseInt(form.querySelector('#form-total').value.replace(/ +/g, ''), 10);
    const user_value = form.querySelector('#user-bet-input');
    const submit = form.querySelector('#bet-submit');

    function set_bet_value(event) {
        const value = parseInt(event.currentTarget.dataset.value, 10);
        user_value.value = window.LOT['total'] + value;
        if (submit) {
            submit.disabled = false;
        }
        new Cleave('#user-bet-input', {
            numeral: true,
            numeralDecimalMark: ',',
            delimiter: ' '
        });
    }

    buttons.forEach((button) => button.addEventListener('click', set_bet_value));

    const cleave = new Cleave('#user-bet-input', {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: ' '
    });

    user_value.addEventListener('input', function() {
        if (submit) {
            submit.disabled = !(parseInt(this.value.replace(/ +/g, ''), 10) > window.LOT ['total']);
        }
    });

    if(form.nodeName.toLowerCase() === 'form') {
        form.addEventListener('ajax:before', (event) => {
            const value = parseInt(event.target['bet'].value.replace(/ +/g, ''), 10) || 0;
            if (value <= window.LOT['total']) {
                event.preventDefault();
                $('.overlay_error').fadeIn();
            }
        });

        form.addEventListener('ajax:success', (event) => {
            const [data, status, xhr] = event.detail;
            // console.log(data);
            if(data.status === 'ok') {
                $('.overlay_done').fadeIn();
                user_value.value = '';
                submit.disabled = true;
            } else if (data.status === 'error') {
                document.getElementById('error_message').innerHTML = data.message;
                $('.overlay_error').fadeIn();
            } else if (data.status === 'redirect') {
                window.location = data.url;
            }
        });
    }
}

export { bet_form };
