function make_template(data, index) {
    return `
    <div class="history-lot__item">
        <span class="history-lot__number">${index}</span>
        <p class="history-lot__name">${data.user}</p>
        <p class="history-lot__date">${data.date}, <span>${data.time}</span></p>
        <p class="history-lot__price">${data.value}&nbsp;UAH</p>
    </div>`;
}


function update_lot_data(data) {
    const members = document.getElementById('lot-members');
    const value_visible = document.getElementById('lot-visible-value');
    const count = document.getElementById('bet-count');
    const total = document.getElementById('form-total');

    const history_desktop = document.getElementById('history-lot-desktop');
    const history_index = history_desktop.querySelectorAll('.history-lot__number').length + 1;

    const template = make_template(data, history_index);

    members.innerHTML = data.members;
    count.innerHTML = data.count;
    try {
        window.LOT['total'] = parseInt(data.value.replace(/ +/, ''), 10);
    } catch(e) {
        // empty
    }
    total.value = data.value;
    value_visible.innerHTML = `${data.value}&nbsp;₴`;
    history_desktop.insertAdjacentHTML('afterbegin', template);

}

export { update_lot_data };
