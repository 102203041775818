import queryString from 'query-string';
import { getCoords } from '../utils/get_coords';

const QUERY = 'ltdr';

function set_filter() {
    const value = this.dataset.value.split('_');
    const params = queryString.parse(location.search);
    let order = value[0];
    let search = '';

    if (QUERY in params) {
        const parsed = params[QUERY].split('_');
        if (parsed[0] === order) {
            params[QUERY] = `${order}_${parsed[1] === 'desc' ? 'asc' : 'desc'}`;
        } else {
            params[QUERY] = `${order}_desc`;
        }
    } else {
        params[QUERY] = `${order}_desc`;
    }

    search = queryString.stringify(params)
    const url = `${window.location.pathname}?${search}`
    window.location = url;
}


function lot_items_filter() {
    const box = document.querySelector('#lots_filter');
    if (!box) {
        return;
    }

    if (window.location.search.indexOf(QUERY) >= 0) {
        try {
            window.scrollTo({
                top: getCoords(document.querySelector('.all-lots')).top
            })
        } catch (e) {
            // empty
        }
    }

    box.querySelectorAll('li').forEach((item) => {
        item.addEventListener('click', set_filter);
    });
}

export { lot_items_filter };
